import React from 'react'

import Layout from '../components/layout'
import collageImage from '../images/collage.jpg'
import Footer from '../components/footer'

const StoryPage = () => (
  <Layout>
    <article>
      <p className="quote">
        “The best way to predict the future is to invent it.”
        <span className="author">― Alan Kay</span>
      </p>
      <p>
        I'm curious to what the world is like. I express it with adventures that
        take me far and wide. Explorer by heart, thinker by habit. I currently
        work at <a href="https://www.nitor.com/en">Nitor</a> in{' '}
        <a href="https://www.google.com/maps/place/Helsinki">Helsinki</a>. I
        grew up in{' '}
        <a href="https://www.google.com/maps/place/Tampere/">
          Tampere, Finland
        </a>
        .
      </p>

      <p>
        I've always had a passion for building things. Be it systems,{' '}
        <a
          href="https://www.facebook.com/RealAudioDevices/"
          target="_blank"
          rel="noopener noreferrer"
        >
          electronics
        </a>
        , furniture or anything I can think of. Good outcomes come from the
        balance of things. My goal is positive change for the future. I believe
        that equality through education and technology creates a better world.
        Most of my work is solving issues by simplifying at scale.
      </p>

      <p>
        I got started in a hurricane of startups. It was quite the whirl but in
        the end I got to do a lot of things and learned a million things. In
        time I have been a trainee, freelancer, employee and a consultant. Most
        of all a continuous learner. Working with good people is always a
        pleasure. And a team of great people even better.
      </p>

      <p>
        I really enjoy music in all forms. Guitar is the instrument I feel
        closest to. The outdoors are something I grew up with and always enjoy a
        good hike. As a tendency of my adventurous nature I travel a lot. Always
        with the preference of going where I haven't been before.
      </p>

      <p>
        Nowadays I use umbrellas. That's how far I've come. Thanks for reading.
      </p>
    </article>
    <img className="fluid-img" src={collageImage} alt="collage" />
    <Footer />
  </Layout>
)

export default StoryPage
